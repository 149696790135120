import React from "react"
import { Link } from "react-router-dom"
import { Fade } from "react-awesome-reveal"
import moment from "moment"
import "moment/locale/cs"
import { DayPicker, DayModifiers } from "react-day-picker"
import { enUS, cs } from "date-fns/locale"
import "react-day-picker/dist/style.css"
import "styles/day-picker.scss"
import { FormattedMessage as M, useIntl, IntlShape, PrimitiveType } from "react-intl"
import axios, { CanceledError } from "axios"

import Firebase, {
    withFirebase,
    Reservation,
    User,
    ParkingSpot,
    areSameDay,
    AbuseInfo,
    Car,
    ReservationType,
    ApiError,
    ApiErrorCode,
    ReservationResultState,
    ReservationStatus
} from "../Firebase"
import { UserStateCheck } from "../../shared-components/Session"
import { WebcamCapture } from "./webcamCapture"
import WebAPI from "../Firebase/webAPI"
import Utilities from "../Firebase/Models/utilities"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import * as ROUTES from "../../constants/routes"

//import { FormRow } from 'components/common';

import Swal from "sweetalert2/dist/sweetalert2.js"
import withReactContent from "sweetalert2-react-content"
const MySwal = withReactContent(Swal)

const richText: { [key: string]: any } = {
    strong: (chunks: any) => <strong>{chunks}</strong>,
    b: (chunks: any) => <b>{chunks}</b>
}

enum SelectionState {
    None = 0,
    Past,
    ReservationOpen,
    ReservationNotOpen,
    Reserved
}

class ReservationData {
    topElement: JSX.Element
    firstButtonText: string
    firstButtonAction: () => void
    secondButtonText: string
    secondButtonAction: () => void

    constructor() {
        this.topElement = <div></div>
        this.firstButtonText = ""
        this.firstButtonAction = () => {}
        this.secondButtonText = ""
        this.secondButtonAction = () => {}
    }
}

interface IReserveSpotBaseState {
    reservations?: Reservation[]
    actionInProgress: boolean
    selectedDay: Date
    selectionState: SelectionState
    selectedReservations: Reservation[]
    occupancyForDay?: number
    parkingSpots: ParkingSpot[]
    pendingReservationsDays: Date[]
    confirmedReservationsDays: Date[]
    declinedReservationsDays: Date[]
    abuseImageData?: string
}
interface IReserveSpotBaseProps {
    user: User
    firebase: Firebase
    intl: IntlShape
}
class ReserveSpotBase extends React.Component<IReserveSpotBaseProps, IReserveSpotBaseState> {
    assignmentHour = 20
    //Enabled calendar period
    enabledStart = new Date()
    enabledEnd = moment().add(5, "days").endOf("isoWeek").add(2, "week").toDate()
    source = axios.CancelToken.source()
    get timezone() {
        return this.props.user.activeLocation?.timezone ?? "Europe/Prague"
    }

    constructor(props: IReserveSpotBaseProps) {
        super(props)

        let selectedDay: Date
        if (new Date().getHours() >= this.assignmentHour) {
            selectedDay = moment().add(1, "days").toDate()
        } else {
            selectedDay = new Date()
        }

        this.state = {
            reservations: undefined,
            actionInProgress: false,
            selectedDay: selectedDay,
            selectionState: SelectionState.None,
            selectedReservations: [],
            occupancyForDay: undefined,
            parkingSpots: [],
            pendingReservationsDays: [],
            confirmedReservationsDays: [],
            declinedReservationsDays: [],
            abuseImageData: undefined
        }

        moment.locale(this.props.intl.locale)
    }

    componentDidMount() {
        this.updateData()
    }

    async updateData() {
        this.setState({ reservations: undefined })
        await Promise.all([this.fetchReservations(), this.fetchParkingSpots()])
        this.handleDayClick(this.state.selectedDay)
    }

    setAsyncState = (newState: any) => {
        return new Promise<void>(resolve => this.setState(newState, resolve))
    }

    fetchParkingSpots = () => {
        return this.props.firebase
            .fetchParkingSpots(this.props.user.activeLocationId, true)
            .then(parkingSpots => {
                this.setState({ parkingSpots })
            })
            .catch(error => {
                console.warn("Error getting parking spots: ", error)
            })
    }

    fetchReservations = () => {
        if (this.props.user.activeLocation === undefined) {
            throw new Error("User has no activeLocation!")
        }

        const startDate = moment.tz(this.timezone).add(-1, "months").startOf("day").toDate()
        const endDate = moment.tz(this.timezone).add(1, "months").endOf("day").toDate()
        return this.props.firebase
            .fetchAllReservations(this.props.user.activeLocation, startDate, endDate, this.props.user.uid)
            .then(allReservations => {
                const reservations = allReservations.filter(r => !r.canceled)
                const pendingReservations = reservations.filter(reservation => reservation.confirmed === undefined || reservation.confirmed === null)
                const confirmedReservations = reservations.filter(reservation => reservation.confirmed)
                const declinedReservations = reservations.filter(reservation => reservation.confirmed === false)
                const pendingReservationsDays = pendingReservations.map(reservation => reservation.startTime)
                const confirmedReservationsDays = confirmedReservations.map(reservation => reservation.startTime)
                const declinedReservationsDays = declinedReservations.map(reservation => reservation.startTime)
                return this.setAsyncState({ reservations, pendingReservationsDays, confirmedReservationsDays, declinedReservationsDays })
            })
    }

    fm = (messageId: string, values: Record<string, PrimitiveType> | undefined = undefined) => {
        return this.props.intl.formatMessage({ id: messageId }, values)
    }

    onSelectChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (event.target.name === "location") {
            this.props.user.activeLocationId = event.target.value
            this.setState({ reservations: undefined })
            await this.props.firebase.setUserActiveLocation(this.props.user, event.target.value)
            this.updateData()
        }
    }

    carSelection = (title = this.fm("reserve.select_car")) => {
        return Promise.resolve()
            .then<any>(_ => {
                const cars = this.props.user.cars
                if (cars && cars!.length === 1) {
                    return Promise.resolve({ value: cars![0].id })
                } else if (cars && cars!.length > 1) {
                    return MySwal.fire({
                        title: title,
                        input: "select",
                        inputOptions: cars!.reduce((a, car) => ({ ...a, [car.id]: car.toString() }), {}),
                        customClass: { input: "select_arrow y-style mt-3" },
                        confirmButtonText: this.fm("reserve.confirm"),
                        showCloseButton: true
                    })
                } else {
                    return Promise.reject(new Error(this.fm("reserve.no_cars")))
                }
            })
            .then(result => {
                if (result?.isDismissed) {
                    //dismissed dialog
                    return Promise.reject(new Error("closed"))
                }
                const carId = result.value
                const car = this.props.user.cars?.find(c => c.id === carId)
                if (!car) {
                    return Promise.reject(new Error("No car selected"))
                }
                return Promise.resolve(car)
            })
    }

    dayReservationFlow = () => {
        const startTime = moment.tz(this.state.selectedDay, this.timezone).startOf("d")
        const endTime = moment.tz(this.state.selectedDay, this.timezone).endOf("d")
        return Promise.resolve({ startTime, endTime })
    }

    halfdayReservationFlow = () => {
        const select = (
            <select className="mb-1 select_arrow y-style" name="period" id="period" defaultValue="day">
                <option key={"am"} value={"am"}>
                    {this.fm("reserve.morning")}
                </option>
                <option key={"pm"} value={"pm"}>
                    {this.fm("reserve.afternoon")}
                </option>
                <option key={"day"} value={"day"}>
                    {this.fm("reserve.whole_day")}
                </option>
            </select>
        )
        return MySwal.fire({
            title: this.fm("reserve.reservation_time"),
            html: select,
            confirmButtonText: this.fm("general.next") + " &rarr;",
            showCancelButton: true,
            preConfirm: () => {
                return (document!.getElementById("period") as HTMLInputElement).value
            }
        }).then(result => {
            if (result.isDismissed) {
                //dismissed dialog
                return Promise.reject(new Error("closed"))
            }
            let startTime = moment.tz(this.state.selectedDay, this.timezone).startOf("d")
            let endTime = moment.tz(this.state.selectedDay, this.timezone).endOf("d")
            if (result.value === "am") {
                endTime = moment.tz(this.state.selectedDay, this.timezone).set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
            } else if (result.value === "pm") {
                startTime = moment.tz(this.state.selectedDay, this.timezone).set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
            }
            return Promise.resolve({ startTime, endTime })
        })
    }

    minutesReservationFlow = () => {
        let startTime: moment.Moment
        return MySwal.fire({
            title: this.fm("reserve.start_time"),
            html: <input id="time-input" className="y-style" defaultValue="08:00" type="time" />,
            confirmButtonText: this.fm("general.next") + " &rarr;",
            showCancelButton: true,
            preConfirm: () => {
                return (document!.getElementById("time-input") as HTMLInputElement).value
            }
        })
            .then(result => {
                if (result.isDismissed) {
                    //dismissed dialog
                    return Promise.reject(new Error("closed"))
                }
                startTime = Utilities.parseTime(result.value!, this.state.selectedDay, this.timezone)
                return MySwal.fire({
                    title: this.fm("reserve.end_time"),
                    html: <input id="time-input" className="y-style" defaultValue="16:00" type="time" />,
                    confirmButtonText: this.fm("general.next") + " &rarr;",
                    showCancelButton: true,
                    preConfirm: () => {
                        return (document!.getElementById("time-input") as HTMLInputElement).value
                    }
                })
            })
            .then(result => {
                if (result.isDismissed) {
                    //dismissed dialog
                    return Promise.reject(new Error("closed"))
                }
                const endTime = Utilities.parseTime(result.value!, this.state.selectedDay, this.timezone)
                return { startTime, endTime }
            })
    }

    addReservation = () => {
        this.setState({ actionInProgress: true })
        let car: Car
        this.carSelection()
            .then(tmpCar => {
                car = tmpCar
                if (this.props.user.activeLocation === undefined) {
                    return Promise.reject(new Error("User has no activeLocation!"))
                }
                if (this.props.user.activeLocation.reservationType === ReservationType.Minutes) {
                    return this.minutesReservationFlow()
                } else if (this.props.user.activeLocation.reservationType === ReservationType.Halfday) {
                    return this.halfdayReservationFlow()
                } else {
                    return this.dayReservationFlow()
                }
            })
            .then(result => {
                MySwal.fire({
                    didOpen: () => {
                        Swal.showLoading()
                    }
                })
                return WebAPI.reserveSpot(this.props.user, result.startTime.toDate(), result.endTime.toDate(), car, true)
            })
            .then(result => {
                this.setState({ actionInProgress: false })

                let title = this.fm("reserve.no_more_spots")
                let html = this.fm("reserve.no_more_spots_long")
                let icon: "success" | "info" = "info"
                if (result.state === ReservationResultState.Confirmed) {
                    title = this.fm("reserve.reservation_submitted")
                    html = this.fm("reserve.your_spot") + " <strong>" + result.parkingSpotName! + "</strong>"
                    icon = "success"
                } else if (result.state === ReservationResultState.Pending) {
                    title = this.fm("reserve.reservation_submitted")
                    /*html = 'Wait for the assignment time to see if you got the parking or not.',*/
                    html = ""
                    icon = "success"
                }
                MySwal.fire({
                    title: title,
                    html: html,
                    icon: icon,
                    showConfirmButton: false,
                    timer: 3500,
                    timerProgressBar: true
                })

                return this.fetchReservations()
            })
            .then(_ => {
                this.handleDayClick(this.state.selectedDay)
            })
            .catch(error => {
                this.setState({ actionInProgress: false })
                if (error?.message === "closed") {
                    return
                }
                console.log("Error: ", error)
                if (error instanceof ApiError && error.code === ApiErrorCode.DuplicateReservation) {
                    return MySwal.fire({
                        title: "Duplicate reservation",
                        text: `You already have such reservation.`,
                        icon: "warning"
                    })
                }
                return MySwal.fire({
                    title: "Error",
                    text: `There was an error while submitting the reservation. ${error?.message || "Please try again later."}`,
                    icon: "error"
                })
            })
    }

    reserveWeek = () => {
        this.setState({ actionInProgress: true })
        this.carSelection()
            .then(car => {
                MySwal.fire({
                    didOpen: () => {
                        Swal.showLoading()
                    }
                })
                const promises: Promise<any>[] = []
                const firstDay = moment.tz(this.state.selectedDay, this.timezone).startOf("isoWeek")
                for (let i = 0; i < 5; i++) {
                    const date = moment(firstDay).add(i, "d")
                    const endDate = moment(date).endOf("d")
                    promises.push(WebAPI.reserveSpot(this.props.user, date.toDate(), endDate.toDate(), car, true))
                }
                return Promise.allSettled(promises)
            })
            .then(results => {
                for (const result of results) {
                    if (
                        result.status === "rejected" &&
                        result.reason instanceof ApiError &&
                        result.reason.code &&
                        ![ApiErrorCode.PastReservation, ApiErrorCode.DuplicateReservation].includes(result.reason.code)
                    ) {
                        return Promise.reject(result.reason)
                    }
                }
                this.setState({ actionInProgress: false })
                MySwal.fire({
                    title: this.fm("reserve.reservations_submitted"),
                    /*text: 'Wait for the assignment time to see if you got the parking or not.',*/
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                    timerProgressBar: true
                })
                return this.fetchReservations()
            })
            .then(_ => this.handleDayClick(this.state.selectedDay))
            .catch(error => {
                this.setState({ actionInProgress: false })
                if (error.message === "closed") {
                    return
                }
                console.log("Error reserving spot: ", error)
                MySwal.fire({
                    title: "Error",
                    text: `There was an error while submitting reservations. ${error.message || "Please try again later."}`,
                    icon: "error"
                })
            })
    }

    cancelReservation = (reservation: Reservation) => {
        MySwal.showLoading()
        this.setState({ actionInProgress: true })
        if (this.props.user.activeLocation === undefined) {
            return Promise.reject(new Error("User has no activeLocation!"))
        }
        return this.props.firebase
            .cancelReservation(this.props.user.activeLocation, reservation)
            .then(_ => {
                this.setState({ actionInProgress: false })
                MySwal.fire({
                    title: this.fm("reserve.cancel_success"),
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                    timerProgressBar: true
                })
                return this.fetchReservations()
            })
            .then(_ => {
                this.handleDayClick(this.state.selectedDay)
            })
            .catch(error => {
                this.setState({ actionInProgress: false })
                MySwal.fire({
                    title: "Error",
                    text: "There was an error while canceling the reservation. Please try again later.",
                    icon: "error"
                })
                console.warn("Error canceling reservation: ", error)
            })
    }

    reportAbuse = (reservation: Reservation) => {
        let newReservationId: string = ""
        this.setState({ actionInProgress: true })
        let carPromise: Promise<Car | null>
        const cars = this.props.user.cars
        if (cars && cars.length > 0) {
            let car = cars.find(car => car.id === reservation.carId)
            if (!car) {
                car = cars.length > 0 ? cars[0] : undefined
            }
            carPromise = Promise.resolve(car ?? null)
        } else if (reservation.carId) {
            carPromise = this.props.firebase.fetchCarForUserId(reservation.carId, reservation.userId)
        } else {
            carPromise = this.props.firebase.fetchCarsForUserId(reservation.userId).then(cars => (cars.length > 0 ? cars[0] : null))
        }
        carPromise
            .then(car => {
                if (!car) {
                    return Promise.reject("Failed to get a car for reservation")
                }
                MySwal.fire({
                    didOpen: () => {
                        Swal.showLoading()
                    }
                })
                return WebAPI.reserveSpot(this.props.user, reservation.startTime, reservation.endTime, car!, false)
            })
            .then(reservationResult => {
                let title = ""
                let html = ""
                if (reservationResult.state === ReservationResultState.Confirmed) {
                    newReservationId = reservationResult.reservationId!
                    title = this.fm("reserve.other_spot_title")
                    html = this.fm("reserve.other_spot", { spotName: reservationResult.parkingSpotName!, ...richText })
                } else {
                    title = this.fm("reserve.no_more_spots")
                    html = this.fm("reserve.no_more_spots_long")
                }
                return MySwal.fire({
                    title: title,
                    html: (
                        <div>
                            <div>{html}</div>
                            <small>{this.fm("reserve.abuser_details")}</small>
                            <br />
                            <div className="my-2">
                                <WebcamCapture capturedImageData={this.abuseImageData} />
                            </div>
                        </div>
                    ),
                    input: "text",
                    inputPlaceholder: "Licence plate number",
                    customClass: { input: "y-style" },
                    confirmButtonText: this.fm("reserve.confirm"),
                    showLoaderOnConfirm: true,
                    preConfirm: inputText => {
                        const abuse = new AbuseInfo({
                            reservationId: reservation.id ?? "unknown",
                            abuserLicense: inputText,
                            spotId: reservation.parkingSpotId ?? "unknown",
                            abuseeId: this.props.user.uid,
                            newReservationId: newReservationId,
                            timestampUpdated: new Date()
                        })
                        return this.props.firebase.addParkingAbuse(this.props.user.activeLocationId, abuse, this.state.abuseImageData)
                    }
                })
            })
            .then(result => {
                if (result.isDismissed) {
                    //dismissed dialog
                    return Promise.reject(new Error("closed"))
                }
                this.setState({ actionInProgress: false })
                return MySwal.fire({
                    title: "Successfully submitted parking abuse",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 3500,
                    timerProgressBar: true
                })
            })
            .then(_ => this.fetchReservations())
            .then(_ => this.handleDayClick(this.state.selectedDay))
            .catch((error: any): any => {
                this.setState({ actionInProgress: false })
                if (error.message === "closed") {
                    return this.fetchReservations()
                }
                console.warn("Error submitting reservation:", error)
                if (error instanceof ApiError && error.code === ApiErrorCode.DuplicateReservation) {
                    return MySwal.fire({
                        title: "Duplicate reservation",
                        text: `You already have such reservation.`,
                        icon: "warning"
                    })
                }
                return MySwal.fire({
                    title: "Error",
                    text: "There was an error. Please try again later.",
                    icon: "error"
                })
            })
    }

    abuseImageData = (imageData: string) => {
        this.setState({ abuseImageData: imageData })
    }

    changeReservationCar = (reservation: Reservation) => {
        this.carSelection()
            .then(car => {
                MySwal.fire({
                    didOpen: () => {
                        Swal.showLoading()
                    }
                })
                reservation.carId = car.id
                if (this.props.user.activeLocation === undefined) {
                    return Promise.reject(new Error("User has no activeLocation!"))
                }
                return this.props.firebase.setReservation(this.props.user.activeLocation, reservation)
            })
            .then(_ => {
                return MySwal.fire({
                    title: this.fm("reserve.car_change_success"),
                    icon: "success",
                    showConfirmButton: false,
                    timer: 3500,
                    timerProgressBar: true
                })
            })
            .then(_ => this.fetchReservations())
            .then(_ => this.handleDayClick(this.state.selectedDay))
            .catch((error: any) => {
                if (error.message === "closed") {
                    return
                }
                console.warn("Error changing a car", error)
                MySwal.fire({
                    title: "Error",
                    text: "There was an error while changing the car. Please try again later.",
                    icon: "error"
                })
            })
    }

    renderDay = (date: Date, modifiers: DayModifiers) => {
        return <span>{date.getDate()}</span>
    }

    handleDayClick = (day: Date): void => {
        const selectedReservations =
            this.state.reservations?.filter(reservation => {
                return areSameDay(reservation.startTime, day)
            }) ?? []

        let selectionState
        if (selectedReservations.some(r => r.confirmed !== false)) {
            selectionState = SelectionState.Reserved
        } else {
            const today = moment.tz(this.timezone).startOf("day").toDate()

            if (day < today) {
                selectionState = SelectionState.Past
            } else if (day <= this.enabledEnd) {
                selectionState = SelectionState.ReservationOpen
            } else {
                selectionState = SelectionState.ReservationNotOpen
            }
        }

        this.setState({
            selectedDay: day,
            selectionState: selectionState,
            selectedReservations: selectedReservations,
            occupancyForDay: undefined
        })

        if (selectedReservations.some(r => r.confirmed === undefined || r.confirmed === null)) {
            this.fetchOccupancyForDate(day)
        }
    }

    fetchOccupancyForDate = (date: Date) => {
        this.source.cancel("Fetching new dates occupancy")
        this.source = axios.CancelToken.source()
        const startTime = moment.tz(date, this.timezone).set({ hour: 10, minute: 0, second: 0, millisecond: 0 }).toDate()
        const locationCode = this.props.user.activeLocation?.code

        if (!locationCode) {
            console.warn("No location code for occupancy request")
            return
        }

        WebAPI.getOccupancy(locationCode, startTime, undefined, this.source.token)
            .then(result => {
                const occupancy = Math.min(1, result.remainingSpots / result.pendingReservations)
                this.setState({ occupancyForDay: occupancy })
            })
            .catch(error => {
                if (error instanceof CanceledError) {
                    console.log("Canceling occupancy request")
                } else {
                    console.warn("Error getting occupancy for date: ", error)
                }
            })
    }

    onSpotClick = (spot?: ParkingSpot) => {
        if (!spot?.imageUrl) {
            return
        }
        MySwal.fire({
            imageUrl: spot.imageUrl,
            showConfirmButton: false,
            showCloseButton: true,
            customClass: { popup: "spotPopup" }
        })
    }

    render() {
        if (this.state.reservations === undefined) {
            return <div className="loader"></div>
        }

        if (this.props.user.cars?.length === 0) {
            return (
                <div className="m-5 text-center">
                    <h5>
                        It seems you have no cars in your profile.
                        <br />
                        Create one before you reserve a parking spot.
                    </h5>
                    <Link to={ROUTES.CARS}>
                        <button type="button" className="btn hm-btn btn-primary m-4">
                            Add a car
                        </button>
                    </Link>
                </div>
            )
        }

        let reservationsText = <div className="loader" />
        if (this.state.occupancyForDay !== undefined) {
            reservationsText = (
                <div>
                    <M id="reserve.parking_chance" />: <b>{Math.floor(this.state.occupancyForDay * 100)} %</b>.
                </div>
            )
        }

        const modifiers = {
            pending: this.state.pendingReservationsDays,
            confirmed: this.state.confirmedReservationsDays,
            declined: this.state.declinedReservationsDays,
            outsideRange: { before: this.enabledStart, after: this.enabledEnd }
        }
        const modifiersClassNames = {
            pending: "rdp-day--pending",
            confirmed: "rdp-day--confirmed",
            declined: "rdp-day--declined",
            outsideRange: "rdp-day--outside-range"
        }

        const userActiveLocation = this.props.user.activeLocation
        let reservationsData: ReservationData[] = []
        if (userActiveLocation) {
            switch (this.state.selectionState) {
                case SelectionState.ReservationOpen: {
                    const reservationData = new ReservationData()
                    reservationData.firstButtonAction = () => this.addReservation()
                    let assignmentTime = moment.tz(userActiveLocation.assignmentDateForDate(this.state.selectedDay), userActiveLocation.timezone)
                    const selectedDateText = Utilities.dateToText(this.state.selectedDay, this.timezone, this.props.intl.locale)
                    const assignmentTimeText =
                        Utilities.dateToText(assignmentTime.toDate(), this.timezone, this.props.intl.locale) +
                        " " +
                        this.fm("reserve.at") +
                        " " +
                        assignmentTime.format("LT")
                    const declinedText =
                        this.state.selectedReservations.every(r => r.confirmed === false) && this.state.selectedReservations.length > 0 ? (
                            <span>
                                <br />
                                <M id="reserve.reservation_declined" values={richText} />
                            </span>
                        ) : (
                            <span></span>
                        )

                    reservationData.topElement = (
                        <div>
                            <M id="reserve.spots_distribution" /> <strong>{selectedDateText}</strong>{" "}
                            {assignmentTime.isAfter() ? this.fm("reserve.will_happen") : this.fm("reserve.happened")} {assignmentTimeText}.
                            {declinedText}
                        </div>
                    )
                    reservationData.firstButtonText = userActiveLocation?.reservationEnabled === false ? "" : this.fm("reserve.reserve")
                    reservationData.secondButtonText = userActiveLocation?.reservationEnabled === false ? "" : this.fm("reserve.reserve_week")
                    reservationData.secondButtonAction = () => this.reserveWeek()
                    reservationsData = [reservationData]
                    break
                }

                case SelectionState.Reserved: {
                    for (const reservation of this.state.selectedReservations) {
                        const reservationData = new ReservationData()
                        const date = Utilities.reservationTimeToText(reservation, this.timezone, this.props.intl.locale)
                        let carText = <span></span>
                        let car: Car | undefined = undefined
                        if (reservation.carId) {
                            car = this.props.user.cars?.find(car => car.id === reservation.carId)
                        }
                        if (car) {
                            if (this.props.user.cars!.length > 1) {
                                carText = (
                                    <span>
                                        <br />
                                        <M id="reserve.for_car" />{" "}
                                        <span className="fake-link" title="change a car" onClick={() => this.changeReservationCar(reservation)}>
                                            {car.toString()}
                                        </span>
                                    </span>
                                )
                            } else {
                                carText = (
                                    <span>
                                        <br />
                                        <M id="reserve.for_car" /> {car.toString()}
                                    </span>
                                )
                            }
                        }
                        const spot = this.state.parkingSpots.find(spot => spot.id === reservation.parkingSpotId)

                        switch (reservation.status()) {
                            case ReservationStatus.Abused:
                                reservationData.topElement = (
                                    <div>
                                        <M id="reserve.reservation_abused" values={{ spotName: reservation.parkingSpot ?? "", ...richText }} />
                                    </div>
                                )
                                reservationsData.push(reservationData)
                                continue
                            case ReservationStatus.Declined:
                                reservationData.topElement = (
                                    <div>
                                        <M id="reserve.reservation_declined_long" values={{ date: date, ...richText }} />
                                    </div>
                                )
                                continue
                            case ReservationStatus.Confirmed:
                                reservationData.topElement = (
                                    <div>
                                        <M id="reserve.reservation_confirmed" values={{ date: date, ...richText }} />
                                        {carText}.<br />
                                        <M id="reserve.your_spot" />{" "}
                                        <strong className={spot?.imageUrl ? "fake-link" : ""} onClick={() => this.onSpotClick(spot)}>
                                            {reservation.parkingSpot ?? ""}
                                        </strong>
                                        .
                                    </div>
                                )
                                break
                            case ReservationStatus.Pending:
                                reservationData.topElement = (
                                    <div>
                                        <M id="reserve.reservation_pending" values={{ date: date, carText: carText, ...richText }} />
                                        <br />
                                        {reservationsText}
                                    </div>
                                )
                                break
                        }

                        reservationData.firstButtonAction = () => this.cancelReservation(reservation)
                        if (areSameDay(reservation.startTime)) {
                            reservationData.firstButtonText = this.fm("reserve.release_spot")
                            reservationData.secondButtonText = this.fm("reserve.spot_occupied")
                            reservationData.secondButtonAction = () => this.reportAbuse(reservation)
                        } else if (reservation.startTime > new Date()) {
                            reservationData.firstButtonText = this.fm("reserve.cancel_reservation")
                        }
                        reservationsData.push(reservationData)
                    }

                    //There are no useful reservations
                    if (
                        this.state.selectedReservations.every(
                            r => r.status() === ReservationStatus.Abused || r.status() === ReservationStatus.Declined
                        ) &&
                        reservationsData.length > 0
                    ) {
                        reservationsData[reservationsData.length - 1].firstButtonAction = () => this.addReservation()
                        reservationsData[reservationsData.length - 1].firstButtonText =
                            userActiveLocation?.reservationEnabled === false ? "" : this.fm("reserve.reserve")
                    }
                    break
                }
            }
        }

        const reservationControlBlock = reservationsData.map((data, i) => (
            <Row className="mx-auto my-3 reservations-panel" key={i}>
                <Fade className="w-100 text-center">{data.topElement}</Fade>
                <div className="mt-3 mx-auto">
                    <button
                        type="submit"
                        className="btn btn-primary hm-btn m-2"
                        hidden={!data.firstButtonText}
                        onClick={data.firstButtonAction}
                        disabled={this.state.actionInProgress}
                    >
                        {data.firstButtonText}
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary hm-btn m-2"
                        hidden={!data.secondButtonText}
                        onClick={data.secondButtonAction}
                        disabled={this.state.actionInProgress}
                    >
                        {data.secondButtonText}
                    </button>
                </div>
            </Row>
        ))

        return (
            <div>
                {this.props.user.locations!.length > 1 ? (
                    <div>
                        <span className="m-2">
                            <M id="reserve.location" />
                        </span>
                        <select
                            className="mb-3 select_arrow y-style"
                            name="location"
                            id="location"
                            value={this.props.user.activeLocationId}
                            onChange={this.onSelectChange}
                        >
                            {this.props.user.locations!.map(location => (
                                <option key={location.code} value={location.code}>
                                    {location.title}
                                </option>
                            ))}
                        </select>
                    </div>
                ) : (
                    <div></div>
                )}
                <Row className="m-0">
                    <Col className="text-center">
                        <DayPicker
                            onDayClick={this.handleDayClick}
                            selected={this.state.selectedDay}
                            showOutsideDays
                            modifiers={modifiers}
                            modifiersClassNames={modifiersClassNames}
                            locale={this.props.intl.locale === "cs" ? cs : enUS}
                        />
                        <div className="mb-md-4">
                            <span className="confirmed-color">confirmed</span>
                            &nbsp;-&nbsp;
                            <span className="pending-color">pending</span>
                            &nbsp;-&nbsp;
                            <span className="declined-color">declined</span>
                        </div>
                    </Col>
                    <Col className="d-flex align-items-center flex-column justify-content-center text-center">
                        {reservationControlBlock}
                        {/*areSameDay(this.state.selectedDay) ?
                        <Link to={ROUTES.RESERVATIONS_OVERVIEW}>
                            <span className="fake-link" title="View allocation">View current allocation</span>
                        </Link> : ""
                        */}
                    </Col>
                </Row>
            </div>
        )
    }
}

const ReserveSpot = (props: any) => {
    const intl = useIntl()
    return (
        <div className="parkfair-container">
            <h3>
                <M id="nav.my_reservations" />
            </h3>
            <hr className="d-none d-md-block" />
            <UserStateCheck>
                {userInfo => (
                    <Fade>
                        <ReserveSpotBase user={userInfo.user} {...props} intl={intl} />
                    </Fade>
                )}
            </UserStateCheck>
        </div>
    )
}

export default withFirebase(ReserveSpot)
